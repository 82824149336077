<template>
  <div v-if="show">
    <h1>minecraft</h1>
    the endrdragon is kool!
    <img style="max-width: 999px; min-width: 999px" src="https://cdn.vox-cdn.com/thumbor/7yHu4xkzY6yAYBM7av-Pmo3Z1T0=/0x0:854x505/920x613/filters:focal(394x186:530x322):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/68563064/bd0ea75cd9da517467cc637efb30950f_videocover.0.jpg" />
    <hr>
    <h1>portal</h1>
    glados is osom
    <img style="max-width: 999px; min-width: 999px" src="https://mygaming.co.za/news/wp-content/uploads/2011/12/Glados-Header.jpg" />
    <hr>
    <h1>harry potter</h1>
    <img style="max-width: 999px; min-width: 999px" src="http://planetbroadcasting.com/wp-content/uploads/2020/04/1aedff54194edc0e9e9450dba0ebac252.jpg" />
    <hr>
    <h1>Totoro</h1>
    <img style="max-width: 999px; min-width: 999px" src="https://getwallpapers.com/wallpaper/full/c/3/0/778012-top-totoro-background-3031x2111-download.jpg" />
    <hr>
    <h1>Lego</h1>
    <img style="max-width: 999px; min-width: 999px" src="https://unothegateway.com/wp-content/uploads/2015/07/lego-movie.jpg" />
    <hr>
    <h1>Jiu Jitsu</h1>
    <img style="max-width: 999px; min-width: 999px" src="https://s3.amazonaws.com/zenplannerwordpress-stack0/wp-content/uploads/sites/148/2018/10/16100428/Jiu-Jitsu-near-Desoto-TX--1200x800.jpg" />
    <hr>
    <h1>Vez'nan</h1>
    <img style="max-width: 999px; min-width: 999px" src="https://vignette.wikia.nocookie.net/character-stats-and-profiles/images/0/09/Veznan.png/revision/latest?cb=20190818193934" />
    <hr>
    <h1>Star Wars</h1>
    <img style="max-width: 999px; min-width: 999px" src="https://wallup.net/wp-content/uploads/2018/03/20/360760-stormtrooper-Kylo_Ren-Star_Wars_The_Force_Awakens-Star_Wars-Sith-lightsaber-artwork.jpg" />
    <hr>
    <h1>Avengers</h1>
    <img style="max-width: 999px; min-width: 999px" src="https://www.pixel4k.com/wp-content/uploads/2020/08/2020-avengers-endgame_1596915054.jpg" />
    <hr>
    <h1>Coding</h1>
    <img style="max-width: 999px; min-width: 999px" src="https://i.ytimg.com/vi/02go-w4X_nM/maxresdefault.jpg" />
    <iframe width="560" height="315" src="https://www.youtube.com/embed/jLbDe2neSD0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <hr>
    <h1>Spongebob Squarepants</h1>
    <img style="max-width: 999px; min-width: 999px" src="https://cdn.cloudflare.steamstatic.com/steam/apps/969990/capsule_616x353.jpg?t=1631105562" />
    <iframe width="560" height="315" src="https://www.youtube.com/embed/r9L4AseD-aA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <hr>
  </div>
</template>
<script>
  export default {
    name: 'Home',
    data() {
      return {
        show: false,
        ronan: "Ronan!",
        password: "glados",
        attempt: ""
      }
    },
    mounted() {
      this.attempt = window.prompt("Enter your password")
      if (this.attempt == this.password ) this.show = true
    }
  }
</script>
<style scoped>
  div {
    background: black;
    color: purple;
  }
</style>